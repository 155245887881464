import { Component, OnInit } from '@angular/core';
import {
  NbCardModule,
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';
import {AuthService} from '../../../auth/service/auth.service';
import {Auth} from '../../../auth/model/Auth';
import {MessageService} from '../../services/message/message.service';
import {TokenStorageService} from '../../../auth/service/token/token.service';
import {ChatRooms} from '../../models/chatRoom/chat-rooms';
import {ChatroomsService} from '../../services/chatroom/chatroom.service';


@Component({
  selector: 'ngx-create-chat-room',
  templateUrl: './create-chat-room.component.html',
  styleUrls: ['./create-chat-room.component.scss'],
})
export class CreateChatRoomComponent implements OnInit {
  chatRoomTitle: string = '';
  selectedUsers: string[] = [];
  userConnected = JSON.parse(localStorage.getItem('auth-user'));
  public connectedUser: Auth = new Auth();
  users: Auth[] = [];
  chatRoom: ChatRooms [] = [] ;
   chatr: ChatRooms = new ChatRooms();
  datecreated: Date;

  constructor(private messageService: MessageService, private authService: AuthService,
              private tokenStorageService: TokenStorageService , private  chatroomService: ChatroomsService,
              private toastrService: NbToastrService) {
  }
  usersLoaded = false;

  ngOnInit(): void {
    this.usersLoaded = true;
    this.connectedUser = JSON.parse(localStorage.getItem('auth-user'));
    this.connectedUser.companyData = this.tokenStorageService.getUser().company;
    setTimeout(() => {
      this.authService.getUsersByMatriculate(this.connectedUser.companyData.matriculateFiscal).subscribe(users => {
        for (let u = 0; u < users.length; u++) {
          if (users[u].email !== this.connectedUser.email) {
            this.usersLoaded = false;
            this.users.push(users[u]);
          }
        }
      });
    }, 2000);
    // this.authService.getAllUsers();
    this.chatroomService.getAllChatRooms().subscribe(
      (chatRoom: ChatRooms[]) => {
        this.chatRoom = chatRoom;
        console.log('test' + this.chatRoom);
      },
    );
  }

  popupOpen: boolean = false;
  cancelCreation() {
    this.popupOpen = false; // Fermer les pop-ups en modifiant la valeur de la variable `popupOpen`
    console.log('fermer');
  }
  createChatRoom(): void {
    this.chatroomService.createChatRoom(this.chatr)
      .subscribe(
        (response: any) => {
          this.showToast('success', 'Add ! ', ' Chat Rooms Added !!');
          console.log('Salle créée avec succès', response);
          console.log(this.chatr);
          console.log(this.chatr.dateCreted);
        },
      );
    window.location.reload();
  }
  // chatr: ChatRooms = new ChatRooms('', '', [], new Date());
  destroyByClick = true;
  duration = 2000;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  hasIcon = true;
  preventDuplicates = false;
  index = 1;

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
