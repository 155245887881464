<nb-card>
  <nb-card-header>
    <h3 class="modal-title">Create Chat Rooms</h3>
  </nb-card-header>
  <nb-card-body>
    <div class="form-group">
      <label for="chat-room-title">Chat Rooms Titel : </label>
      <input nbInput id="chat-room-title" required [(ngModel)]="chatr.title" placeholder="Title"/>
    </div>
    <div class="form-group">
      <label for="selected-users">Users : </label>
<!--      <nb-select id="selected-users" [(ngModel)]="selectedUsers" multiple>-->
      <nb-select class="col-sm-9" id="selected-users" [(ngModel)]="chatr.users" multiple>
        <nb-option *ngFor="let user of users" [value]="user.id" required>{{ user.email }}</nb-option>
      </nb-select>
    </div>
    <div>      <label for="selected-users">Date  : </label>
      <input nbInput placeholder="Pick Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="chatr.dateCreted">
      <nb-date-timepicker withSeconds #dateTimePicker></nb-date-timepicker>
    </div>
  </nb-card-body>
  <nb-card-footer>
   <div>  <button nbButton status="success"  (click)="createChatRoom()">Créer</button>
    <button nbButton status="danger"  (click)="cancelCreation()">Close</button></div>
  </nb-card-footer>
</nb-card>

