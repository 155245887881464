import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {ChatRooms} from '../../models/chatRoom/chat-rooms';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {filter, map} from 'rxjs/operators';
import {Message} from '../../models/message/message';

@Injectable({
  providedIn: 'root',
})
export class ChatroomsService {
  webSocket: WebSocket;
  private socket$: WebSocketSubject<any>;


  constructor(private httpClient: HttpClient) {
    // this.webSocketSubject = webSocket('ws://localhost:8090/api/chatRoom');

  }
  chatMessage: Message[] = [];
  public  openWebSocket() {
    this.webSocket = new WebSocket('ws://marketing.manajero.com/chat');
    this.webSocket.onopen = (event) => {
      console.log('open' , event);
    };
    this.webSocket.onmessage = (event) => {
      const chatMessage = JSON.parse(event.data);
      this.chatMessage.push(chatMessage);
    };
    this.webSocket.onclose = (event) => {
      console.log('close' , event);
    };
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    // Replace with the URL of your Spring Boot WebSocket server
  };
  getAllChatRooms(): Observable<ChatRooms[]> {
    return this.httpClient.get<ChatRooms[]>(`${environment.comMarketing}/api/chatRoom/all`, this.httpOptions);

  }

  // getChatRoomById(id: string): Observable<ChatRoom> {
  //   const url = `${this.chatRoomsUrl}/${id}`;
  //   return this.http.get<ChatRoom>(url);
  // }

  createChatRoom(chatRoom: ChatRooms): Observable<ChatRooms> {
    return this.httpClient.post<ChatRooms>(`${environment.comMarketing}/api/chatRoom/create`, chatRoom, this.httpOptions);
  }
  connect(): WebSocket {
    return this.webSocket;
  }
  sendMessage(message: string): void {
    this.socket$.next({ type: 'message', content: message });
    // console.log(sendForm.value);
  }

  // receiveMessage(): Observable<any> {
  //   return this.webSocketSubject.asObservable();
  // }

  // updateChatRoom(id: string, chatRoom: ChatRoom): Observable<ChatRoom> {
  //   const url = `${this.chatRoomsUrl}/${id}`;
  //   return this.http.put<ChatRoom>(url, chatRoom);
  // }

  // deleteChatRoom(id: string): Observable<void> {
  //   const url = `${this.chatRoomsUrl}/${id}`;
  //   return this.http.delete<void>(url);
  // }

  // fetchGroupChatMessages(groupId: string): Observable<any> {
  //   // Envoyer une demande au serveur pour récupérer les messages de la salle de discussion de groupe spécifiée
  //   const request = { type: 'fetch_group_chat_messages', groupId };
  //   this.webSocket.next(request);
  //
  //   // Renvoyer l'observable pour recevoir les messages de la salle de discussion de groupe
  //   return this.webSocket.asObservable().pipe(
  //     filter(response => response.type === 'group_chat_messages' && response.groupId === groupId),
  //     map(response => response.messages),
  //   );
  // }
}
