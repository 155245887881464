import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './components/messages/messages.component';
import {CreateChatRoomComponent} from './components/create-chat-room/create-chat-room.component';
import {
    NbButtonGroupModule, NbButtonModule,
    NbCardModule,
    NbChatModule, NbDatepickerModule, NbFormFieldModule, NbIconModule, NbInputModule,
    NbListModule, NbSelectModule,
    NbSpinnerModule,
    NbThemeModule,
    NbUserModule, NbWindowModule,
} from '@nebular/theme';
import {ChatRoutingModule} from './chat-routing.module';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    MessagesComponent,
    CreateChatRoomComponent,
  ],
    imports: [
        CommonModule,
        NbCardModule,
        NbListModule,
        NbChatModule,
        ChatRoutingModule,
        NbSpinnerModule,
        NbButtonGroupModule,
        NbUserModule,
        NbThemeModule.forRoot(),
        FormsModule,
        NbButtonModule,
        NbSelectModule,
        NbInputModule,
        NbDatepickerModule,
        NbIconModule,
        NbWindowModule,
        NbFormFieldModule,
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add this line
})
export class ChatModule { }
