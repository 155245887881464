import {Auth} from '../../../auth/model/Auth';
import {Message} from '../message/message';
export class ChatRooms {

  public idrooms: String;
  public title: String;
  public users: String[];
  // public users: Auth[];
  public dateCreted?: Date = new Date();

  // get idrooms(): String {
  //   return this._idrooms;
  // }
  //
  // set idrooms(value: String) {
  //   this._idrooms = value;
  // }
  //
  // get title(): String {
  //   return this._title;
  // }
  //
  // set title(value: String) {
  //   this._title = value;
  // }
  //
  //   get dateCreted(): Date {
  //   return this._dateCreted;
  // }
  //
  // set dateCreted(value: Date) {
  //   this._dateCreted = value;
  // }
  //
  // get users(): string[] {
  //   return this._users;
  // }
  //
  // set users(value: string[]) {
  //   this._users = value;
  // }
  // // constructor(idrooms: string, title: string, users: string[], dateCreted: Date) {
  // //   this.idrooms = idrooms;
  // //   this.title = title;
  // //   this.users = users;
  // //   this.dateCreted = dateCreted;
  // // }
}
