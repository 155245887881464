;
import { Oauth2CallbackComponent } from './components/oauth2-callback/oauth2-callback.component'
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  NbCardModule,

  NbIconModule, NbLayoutModule, NbSpinnerModule,

} from '@nebular/theme';

import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProfileComponent } from './components/profile/profile.component';
// import { NgxCaptchaModule } from 'ngx-captcha';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { UnlockingComponent } from './components/unlocking/unlocking.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './helpers/auth-interceptor.service';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {
  NbAuthModule,
  NbOAuth2AuthStrategy,
  NbOAuth2ResponseType,
} from '@nebular/auth';


@NgModule({
  declarations: [ ForgetPasswordComponent, ResetPasswordComponent, ProfileComponent, UnlockingComponent, Oauth2CallbackComponent],
    imports: [
        AuthRoutingModule,
        CommonModule,
        FormsModule,
        RouterModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NbAuthModule,
        NbCardModule,
        NbIconModule,
        ReactiveFormsModule,

        NbLayoutModule,
        NbSpinnerModule,
        RecaptchaModule,
        NgxIntlTelInputModule,
        NbAuthModule.forRoot({
          strategies: [
            NbOAuth2AuthStrategy.setup({
              name: 'google',
              clientId: '1006302745056-7hglls1t3nhl3nn5dn7oi17fkd2nosuk.apps.googleusercontent.com',
              clientSecret: '',
              authorize: {
                endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
                responseType: NbOAuth2ResponseType.TOKEN,
                scope: 'https://www.googleapis.com/auth/userinfo.profile https://mail.google.com/',
                redirectUri: 'https://admin.manajero.com/administration/dashboard',
              }, 
    
              redirect: {
                success: '/example/oauth2',
              },
            }),
          ],
        }),

    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
})
export class AuthModule {}
